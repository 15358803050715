const MENU_WIDTH = 300;

/* @ngInject */
function SheetDirective(NAV_TEMPLATE_API_PREFIX, $rootScope) {
    return {
        restrict: 'EA',
        templateUrl: `${NAV_TEMPLATE_API_PREFIX}/sheet.html`,
        link(scope, el) {
            let appearAnimation = 'zoomIn';
            let dismissAnimation = 'zoomOut';
            if (scope.pin) {
                appearAnimation = 'fadeInUp';
                dismissAnimation = 'fadeOutDown';
            }

            if (
                scope.menu &&
                scope.positionMenuAround &&
                !$rootScope.device.isPhone
            ) {
                const source = $(scope.positionMenuAround);
                const rect = scope.positionMenuAround.getBoundingClientRect();
                const sourceWidth = source.outerWidth();
                const sourceOffset = rect || source.offset();
                const verticalOffset =
                    scope.vOffset || (source ? source.outerHeight() : 0);
                const horizontalOffset = scope.hOffset || 0;
                const anchor = scope.anchor ? scope.anchor : 'top right';
                const anchors = anchor.split(' ');
                const style = {
                    top: 0,
                    left: 0,
                    bottom: 'auto'
                };

                appearAnimation = 'zoomInTopLeft';
                dismissAnimation = 'zoomOutTopLeft';

                anchors.forEach(a => {
                    switch (a) {
                        case 'top':
                            style.top = sourceOffset.top;
                            break;

                        case 'right':
                            style.left =
                                sourceOffset.left + sourceWidth - MENU_WIDTH;
                            break;

                        case 'bottom':
                            style.top = sourceOffset.top + source.outerHeight();
                            break;

                        case 'left':
                            style.left = sourceOffset.left;
                            appearAnimation = 'zoomInTopRight';
                            dismissAnimation = 'zoomOutTopRight';
                            break;
                    }
                });

                style.top = style.top + verticalOffset;
                style.left = Math.max(style.left + horizontalOffset, 0);

                el.find('.modal-content').css(style);
            }

            if (scope.menu) {
                el.find('.modal-content').css({ bottom: 'unset' });
            }

            el.find('.modal-content').addClass(appearAnimation);

            scope.$$deferred().promise.finally(() => {
                if (!(scope.menu && $rootScope.device.isPhone)) {
                    el.addClass('animated fadeOut');
                }

                el.find('.modal-content')
                    .removeClass(appearAnimation)
                    .addClass(dismissAnimation);

                el.find('.modal-sheet').addClass('disappearing');

                // self-destruct after the animation is done
                setTimeout(() => el.remove(), 600);
            });

            // Using ngTouch/ngClick on the .modal-sheet prevents input fields from getting focused
            // on iOS, Windows Mobile, BlackBerry and some Androids
            // since we need it only if dismissOnOutsideClick is enabled we're selectevly adding it here
            // related: http://git.io/vscR4 issue in angular.js
            if (scope.dismissOnOutsideClick) {
                let dismissHandler = ev =>
                    scope.$evalAsync(() => scope.$dismissOnOutsideClick(ev));

                el.find('.modal-sheet').on('click touchend', dismissHandler);
            }

            scope.$dismissOnOutsideClick = ev => {
                setTimeout(() => {
                    const $target = angular.element(ev.target);
                    if ($target.closest('.modal-sheet').length === 0) {
                        console.debug(
                            '[SheetDirective] Prevent dismiss on sensible areas.'
                        );
                        return;
                    }

                    if (scope.dismissOnOutsideClick) {
                        scope.reject('click outside');
                    }
                }, 100);
            };
        }
    };
}

angular.module('maestro.directives').directive('sheet', SheetDirective);
