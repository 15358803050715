import { isFunction } from 'lodash';

export default class EventEmitter {

    constructor($timeout = (handler) => setTimeout(handler, 0)) {
        this.$timeout = $timeout;
        this._eventListeners = {};
    }

    /**
     * Adds a listener for an event
     *
     * @param {String} eventType Name of the event
     * @param {Function} listener Of shape: (payload: Object) => Any
     * @return {Function} Function to unsubscribe from the event. Of shape: () => Void
     */
    addEventListener(eventType, listener) {
        if (!isFunction(listener)) {
            return;
        }

        this._eventListeners[eventType] = this._eventListeners[eventType] || [];
        this._eventListeners[eventType].push(listener);
        return () => this.removeEventListener(eventType, listener);
    }

    /**
     * Removes a listener for an event
     *
     * @param {String} eventType Name of the event
     * @param {Function} listener Of shape: (payload: Object) => Any
     */
    removeEventListener(eventType, listener) {
        if (this._eventListeners[eventType]) {
            this._eventListeners[eventType] = this._eventListeners[eventType].filter(l => l !== listener);
        }
    }

    /**
     * Emits an event to all its listeners
     *
     * @param {String} eventType Name of the event
     * @param {any} [payload] What to send to the listeners alongside the event
     */
    emit(eventType, payload) {
        const listeners = this._eventListeners[eventType] || [];
        listeners.forEach(listener => this.$timeout(async () => await listener(payload)));
    }

    /**
     * tells whether a eventType still has listeners
     *
     * @param {String} eventType Name of the event
     * @return {Boolean} hasListeners
     */
    hasListeners(eventType) {
        if (this._eventListeners[eventType]) {
            return this._eventListeners[eventType].length;
        }
        return false;
    }

    getListeners() {
        return this._eventListeners;
    }
}
