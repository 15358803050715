import { v4 as uuid } from 'uuid';
import { isFunction } from 'lodash';

/* @ngInject */
function MetricsService(
    $window,
    $http,
    $cookies,

    EID,
    PID,
    SETTINGS,
    ACTIVATED_PERSON,
    BRANDING,
    EVENT,
    ANALYTICS_ENDPOINT
) {

    // use static token in dev mode
    const sessionId = uuid();

    const stopTracking = () => {
        const beacons = Object.keys($cookies.getAll()).filter(c =>
            c.startsWith('mp_')
        );

        beacons.forEach(b => {
            // Remove cookie from main domain
            const host = location.host.replace(/(:[0-9]{1,5})?$/, '');

            $cookies.remove(b, { domain: `.${host}` });

            // Remove cookie from TLD
            let tld = host.split('.');

            tld.shift();
            tld = tld.join('.');
            $cookies.remove(b, { domain: `.${tld}` });
        });
    };

    const isTrackingForbidden = () => {
        return (
            ACTIVATED_PERSON.do_not_track ||
            EVENT.show_cookie_banner && $cookies.get('cookies-policy') !== '1'
        );
    };

    if (isTrackingForbidden()) {
        stopTracking();
    }

    // send device status to backend
    const sendDeviceStatus = () => {
        const url = `/api/v1/eid/${EID}/log/app/status`;
        const postData = {
            platform: 'web',
            device: 'web',
            device_id: `${EID}-${PID}-browser`,
            browser_version: $window.navigator.userAgent,
            system_locale: $window.navigator.language,
            app_version: "1.153.0"
        };

        return $http
            .post(url, postData)
            .catch(err => console.error('[metricsService] Failed to send device status', err));
    };


    sendDeviceStatus();

    // API

    /**
     * Bootstrap event tracking.
     */
    const init = () => {
    };

    /**
     * Simple tracking.
     * @param {String} event tracking event name
     * @param {Object} properties the event properties to log
     */

    const track = () => {
        console.log('[MetricsService] depreciated track() called');
    };

    /**
     * Start active tracking.
     * @param {String} event tracking event name
     * @param {Object} properties the event properties to log
     * @param {Boolean} trackHidden if true, event duration will be tracked, even when webapp is in background
     */

    const startTrack = () => {
        console.log('[MetricsService] depreciated startTrack() called');
    };

    /**
     * End active tracking.
     * @param {String} trackedId tracking id returned by startTrack
     * @param {Object} extraProperties optional properties to merge with the original properties of the tracked event
     */

    const endTrack = () => {
        console.log('[MetricsService] depreciated endTrack() called');
    };

    /**
     * Exposed API for in-house analytics
     * @param {Object} event the payload to send
     * @param {String} event.name the event name
     */
    const trackAnalytics = (event) => {
        if (!ANALYTICS_ENDPOINT) {
            return;
        }

        return $http
            .post(ANALYTICS_ENDPOINT, Object.assign({
                app_version: "1.153.0",
                session_id: sessionId
            }, event))
            .catch(err => console.error('[metricsService] Failed to send analytics', err));
    };

    const tryCatchWrapper = (f) => {
        if (!isFunction(f)) {
            return f;
        }
        return async function(...args) {
            try {
                return await f(...args);
            } catch (e) {
                console.error(`[metricsService] ${f.name} error`, e);
            }
        };
    };

    return {
        init: tryCatchWrapper(init),
        track: tryCatchWrapper(track),
        startTrack: tryCatchWrapper(startTrack),
        endTrack: tryCatchWrapper(endTrack),
        stopTracking: tryCatchWrapper(stopTracking),
        trackAnalytics: tryCatchWrapper(trackAnalytics),
        get sessionId() {
            return sessionId;
        }
    };
}

angular
    .module('maestro.services.metrics', [ 'dbinterface' ])
    .service('metricsService', MetricsService);
