import { each, extend, find, isObject, isArray, isEmpty, uniq, get } from 'lodash';
import moment from 'moment-timezone';
import { FilteringController } from '../../common/filtering';
import { selectNextSession } from '../../../utils/agenda-sessions';

export const AgendaNavComponent = {
    bindings: {
        config: '<'
    },
    template: require('./agenda-nav.jade'),
    controller: class AgendaNavComponent extends FilteringController {
        /* @ngInject */
        constructor(
            THEME,
            EVENT,
            SETTINGS,

            $element,
            $q,
            $http,
            $i18n,
            $popover,
            $scope,

            dataSourceEvaluatorService,
            navService,
            progressBarService,
            localCacheFactory,
            metricsService
        ) {
            super(THEME, EVENT._id, $q, $popover, $i18n, $http, dataSourceEvaluatorService);

            this.event = EVENT;
            this.settings = SETTINGS;
            this.$q = $q;
            this.ds = dataSourceEvaluatorService;
            this.navService = navService;
            this.progressBarService = progressBarService;
            this.$i18n = $i18n;
            this.$popover = $popover;
            this.$element = $element;
            this.lastSelectedDayCache = localCacheFactory('agenda/lastSelectedDay');
            this.filtersCache = localCacheFactory('agenda/selectedFilters');

            this.searchQuery = '';
            this.selectedFilters = [];
            this.pages = {};
            this.notificationSubscriptions = [];

            this.performActions = actions =>
                navService.performActions(actions, this.config.nuid);

            this.lastRowIsBelowFold = () => {
                const lastRow = $element.find('component-loader:last')[0];
                // nothing on the screen at all
                if (!lastRow) return true;

                const container = $element.find('.agenda-nav-list-items')[0];
                console.log(
                    '[AgendaNavComponent] lastRowIsBelowFold',
                    lastRow.offsetTop,
                    container.clientHeight,
                    lastRow.offsetTop > container.clientHeight
                );
                return lastRow.offsetTop > container.clientHeight;
            };

            this.metricsService = metricsService;

            $scope.$on('bottom-reached', () => this.nextPage());
        }

        $onInit() {
            console.log('[AgendaNavComponent] init', this.config);

            const lastSelectedDay = this.lastSelectedDayCache.get('day');

            this.progressBarService.startTask();
            this.ds
                .eval(this.config.agenda_days_ds)
                .then(
                    info => {
                        this.days = info.days;
                        this.selectedDay = typeof lastSelectedDay === 'number' && info.days[lastSelectedDay].enabled ?
                            lastSelectedDay : info.selected_day_index;
                        this.nextPage();
                    },
                    resp => console.error('agenda days error', resp)
                )
                .finally(() => this.progressBarService.finishTask());

            this.notificationSubscriptions.push(
                this.navService.on('rows:update', this.onRowUpdate.bind(this))
            );

            this.getFilters();

            const cacheFilters = this.filtersCache.get('filters') || null;
            const selectedFilters = cacheFilters && JSON.parse(cacheFilters);

            if (!isEmpty(selectedFilters)) {
                this.selectedFilters = selectedFilters;
                this.onApplyInlineFilters(selectedFilters);
            }
        }

        $onDestroy() {
            super.$onDestroy();

            const subs = this.notificationSubscriptions;
            if (subs && subs.length) {
                each(subs, unsub => unsub());
            }
        }

        onDayChange({ index }) {
            console.log('[AgendaNavComponent] onDayChange', this.days[index]);

            this.lastSelectedDayCache.put('day', index);

            this.selectedDay = index;
            const day = this.days[index];
            if (!day.sessions || !day.sessions.length) {
                this.nextPage();
            } else {
                this.scrollToNextSession();
            }
        }

        onPageRendered() {
            console.log('[AgendaNavComponent] onPageRendered');
            if (!this.lastRowIsBelowFold()) {
                console.log('[AgendaNavComponent] requesting more');
                this.nextPage();
            }
        }

        updatePictures() {
            this.$element.find('[lazy-background-asset-src]').trigger('assets:load');
        }

        onRowUpdate({ target, updates }) {
            const day = this.days[this.selectedDay];
            const sessions = day.sessions;
            const isTargetedSession = ({ id }) => id === target;

            let targetedSession = find(sessions, isTargetedSession);
            // Find in other days as well. it's most likely to be in current select day
            if (!targetedSession) {
                each(this.days, ({ sessions }) => {
                    if (targetedSession || !sessions) return;
                    targetedSession = find(sessions, isTargetedSession);
                });
            }
            if (!targetedSession) return;

            extend(targetedSession, updates);
            console.log('[AgendaNavComponent] updated row', target, updates);
        }

        reload() {
            each(this.days, (day, index) => {
                day.nextPage = null;
                delete day.loaded;
                if (index !== this.selectedDay) {
                    day.sessions = [];
                    delete day.empty;
                }
            });
            this.nextPage(true);
        }

        nextPage(reset = false) {
            if (!this.days || !this.days[this.selectedDay]) return;

            const day = this.days[this.selectedDay];
            const page = day.nextPage || day.page;

            if (!page
                || this.loading
                || day.empty && !this.selectedFilters.length
                || day.loaded && !reset
            ) {
                return;
            }

            console.log('[AgendaNavComponent] nextPage', day, page);

            this.loading = true;

            const dsParams = {
                page,
                search: this.searchQuery.length ? this.searchQuery : undefined
            };
            if (this.selectedFilters.length) {
                dsParams.filters = this.selectedFilters;
                // previous impose param may exist, so clear it
                delete dsParams.impose;
            }

            this.ds
                .eval(this.config.ds, dsParams)
                .then(rows => {
                    day.sessions = reset ?
                        rows
                        : uniq((day.sessions || []).concat(rows), ({ id }) => id);

                    this.scrollToNextSession();

                    const lastRow = rows[rows.length - 1];
                    if (lastRow) {
                        console.log('setting next page to', lastRow.next_page);
                        day.nextPage = lastRow.next_page;
                        if (!day.nextPage) {
                            day.loaded = true;
                        }
                    }

                    if (isEmpty(day.sessions)) {
                        day.empty = true;
                    }
                })
                .finally(() => {
                    this.updatePictures();
                    this.loading = false;
                });
        }

        scrollToNextSession() {
            const today = new Date();
            const now = Math.round(today.getTime() / 1000);
            const day = this.days[this.selectedDay];
            const date = new Date(day.timestamp * 1000);
            const tzSettings = get(this.settings, 'date.timezone', 'device');
            const momentDate = tzSettings === 'device' ? moment(date) : moment.tz(date, this.event.timezone);

            this.isToday = momentDate.isSame(today, 'day');

            if (this.isToday) {
                this.scrollToSession = selectNextSession(day.sessions, now);
            }
        }

        // / Search ///

        toggleSearch() {
            this.showSearch = !this.showSearch;
            if (!this.showSearch) {
                this.searchQuery = '';
                this.reload();
            }
        }

        onSearchQueryChange() {
            // because we're using input debounce, input might not be visible
            // if (!this.showSearch) return;

            console.log(
                '[AgendaNavComponent] onSearchQueryChange',
                this.searchQuery
            );

            this.reload();

            const payload = {
                list_id: this.config._id,
                query: this.searchQuery,
                filters: this.selectedFilters
            };

            console.debug(
                '[AgendaNavComponent] Tracking filters agenda-search',
                payload
            );
        }

        // / Utils ///

        hasNoSessions() {
            const day = this.days && this.days[this.selectedDay];
            if (!day) return true;
            const sessions = day.sessions;
            return sessions ? sessions.length === 0 : true;
        }

        noResulsImage() {
            const type = this.isFilteringOrSearchingActive()
                ? 'no_results'
                : 'no_content';
            return this.config[type].icon;
        }

        noResultsLabel() {
            const type = this.isFilteringOrSearchingActive()
                ? 'no_results'
                : 'no_content';
            return this.$i18n(this.config[type].label);
        }

        isFilteringOrSearchingActive() {
            return this.searchQuery || this.selectedFilters.length;
        }

        restoreDayState() {
            const day = this.days[this.selectedDay];
            delete day.empty;
        }

        cancelFilteringAndSearching() {
            this.searchQuery = '';
            this.showSearch = false;
            this.clearFilters();
        }

        showFilters($event) {
            super.showFilters($event);

            console.debug(
                '[AgendaNavComponent] Tracking filters agenda-filters-open'
            );
        }

        clearFilters() {
            this.restoreDayState();
            super.clearFilters();
            this.trackFilterClear();
            this.filtersCache.remove('filters');
        }

        onApplyFilters(filtersApplied) {
            this.onApplyInlineFilters(filtersApplied);
        }

        onApplyInlineFilters(filtersApplied) {
            console.log('[AgendaNavComponent] applying filters', filtersApplied);

            if (filtersApplied === 'cleared') {
                return this.clearFilters();
            }

            if (isEmpty(filtersApplied)) {
                this.restoreDayState();
            }

            let filters = this.selectedFilters;
            if (isObject(filtersApplied)) {
                filters = filtersApplied;
            }

            const payload = {
                list_id: this.config._id,
                apply: filtersApplied !== 'closed',
                filters
            };

            console.debug('[AgendaNavComponent] Tracking filters agenda-filters-close', payload);

            this.selectedFilters = isArray(filtersApplied) ? filtersApplied : [];
            this.appliedFiltersLabel = this.getAppliedFiltersLabel();

            this.filtersCache.put('filters', JSON.stringify(this.selectedFilters));
            this.reload();
        }

        trackFilterClear() {
            console.debug('[AgendaNavComponent] Tracking filters agenda-filters-clear');
        }
    }
};
