import { each, filter, get } from 'lodash';

const TRACKED_CTX = [ 'polls' ];

class NotificationsService {
    /** @ngInject */
    constructor(databaseService, pollService, $interval, $eventBus) {
        this.databaseService = databaseService;
        this.pollService = pollService;
        this.$interval = $interval;
        this.scheduledNotifications = [];
        this.interval = 1000 * 60 * 5; // 5 minutes
        this.scheduledIntervals = {};
        this.contexts = {};

        $eventBus.on('notifications:hide', ctx => this.contexts[ctx] = true);
        $eventBus.on('notifications:show', ctx => delete this.contexts[ctx]);
    }

    /**
     * Gets all notifications
     *
     * @param {Function} onPushNotification The function to execute when attempting to push a notification
     */
    checkForNewNotification(onPushNotification) {
        this.getAllNotifications().then(
            ({ data: { response } }) => {
                const notificationsToSchedule = filter(response, notification => {
                    return notification.delivery_mode === 'schedule' &&
                        !notification.local_shown_on_web &&
                        !notification.isRead &&
                        !this.scheduledNotifications.includes(notification._id);
                });
                if (notificationsToSchedule.length) {
                    this.scheduleNotifications(notificationsToSchedule, onPushNotification);
                }
            }
        );
    }

    /**
     * Schedules notifications by starting an interval
     *
     * @param {Array} notifications The notifications to schedule
     * @param {Function} onPushNotification The function to execute when attempting to push a notification
     */
    scheduleNotifications(notifications, onPushNotification) {
        const now = Math.round(Date.now() / 1000);

        each(notifications, (notification) => {
            this.scheduledNotifications.push(notification._id);
            const timeout = (notification.timestamp - now) * 1000;
            if (timeout < 0) return;

            this.scheduledIntervals[notification._id] = this.$interval(() => {
                // avoid showing the notification earlier than it should be shown
                const nowTs = Math.round(Date.now() / 1000);
                if ((notification.timestamp - 100) > nowTs) {
                    return this.$interval.cancel(this.scheduledIntervals[notification._id]);
                }

                onPushNotification({
                    notificationLinkId: notification.linkId,
                    notificationPayload: {
                        message: {
                            title: notification.title,
                            body: notification.body
                        },
                        icon: 'icon.spotman.bell/file.png',
                        action: {
                            path: 'notifications/open-from-push',
                            params: { notification }
                        }
                    }
                });
                this.$interval.cancel(this.scheduledIntervals[notification._id]);
            }, timeout);
        });
    }

    /**
     * Gets all notifications
     *
     * @returns {Promise<Object>} the appscript response containing the notifications
     */
    getAllNotifications() {
        return this.databaseService.runAppScript('notifications/get-all-notifications', {
            includeFutureNotifications: true
        });
    }

    /**
     * Starts the interval loop for checking if a local notification should be pushed
     *
     * @param {Function} onPushNotification The function to execute when attempting to push a notification
     */
    startCheckInterval(onPushNotification) {
        this.checkInterval = this.$interval(
            () => this.checkForNewNotification(onPushNotification),
            this.interval
        );
    }

    /**
     * Destroys the interval loop for checking if a local notification should be pushed
     */
    cancelCheckInterval() {
        this.$interval.cancel(this.checkInterval);
    }

    /**
     * check if notification should be displayed to the participant
     */
    shouldDisplayNotification(ctx, docId) {
        if (!ctx || !docId) {
            return true;
        } else {
            return !this.contexts[`${ctx}-${docId}`];
        }
    }

    /**
     * track notification reception
     */
    trackNotificationReception(notificationPayload) {
        const context = get(notificationPayload, 'ctx');

        if (TRACKED_CTX.includes(context)) {
            this.trackPollNotificationReception(notificationPayload);
        }
    }

    /**
     * track poll notification reception
     */
    trackPollNotificationReception(notificationPayload) {
        const type = get(notificationPayload, 'type');
        const execution_id = get(notificationPayload, 'execution_id');

        if (!type || !execution_id) return;

        if (type === 'vote') {
            return this.pollService.onExecution(execution_id);
        } else {
            return this.pollService.onResultsShown(execution_id);
        }
    }
}

angular
    .module('maestro.services')
    .service('notificationsService', NotificationsService);
