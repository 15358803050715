const WebSocketsService = require('./vanilla/webSocketsService').default;
import Primus from 'primus';

window.APP.services = {
    webSockets: new WebSocketsService(Primus)
};

angular.module('maestro.services', []);
require('./appcuesService');
require('./authService');
require('./dataSourceEvaluatorService');
require('./databaseService');
require('./documentService');
require('./eventBusService');
require('./frontstageLoaderService');
require('./handlerService');
require('./liveStreamService');
require('./localCacheService');
require('./mediaDevicesService');
require('./metricsService');
require('./navFormService');
require('./navRouterService');
require('./navService');
require('./notificationsService');
require('./offlineService');
require('./paginatorFactory');
require('./pdfService');
require('./pictureInPictureService');
require('./popoverService');
require('./progressBarService');
require('./reactionsService');
require('./qnaService');
require('./pollService');
require('./sheetService');
require('./styleParserService');
require('./triggerService');
require('./uiService');
require('./videoBreakoutService');
require('./videoCallModeratorService');
require('./videoCallService');
require('./visibilityService');

const TargetingService = require('./targetingService').default;
angular.module('maestro.services').service('targetingService', TargetingService);

const CrashReportingService = require('./crashReportingService').default;
angular.module('maestro.services').service('crashReportingService', CrashReportingService);

const CdnCookiesProvider = require('./cdnCookiesProvider').default;
angular.module('maestro.services').service('cdnCookiesProvider', CdnCookiesProvider);

const LibLoaderService = require('./libLoaderService').default;
angular.module('maestro.services').service('libLoaderService', LibLoaderService);

const VideoPlayerService = require('./videoPlayerService').default;
angular.module('maestro.services').service('videoPlayerService', VideoPlayerService);

const WebsocketConnectionReportingService = require('./websocketConnectionReportingService').default;
angular.module('maestro.services').service('websocketConnectionReportingService', WebsocketConnectionReportingService);

const SignalService = require('./signalService').default;
angular.module('maestro.services').service('signalService', SignalService);
