import { BaseService } from './baseService';

export default class SignalService extends BaseService {

    /* @ngInject */
    constructor($http, databaseService) {
        super($http);

        this.databaseService = databaseService;

        this.appScriptListeners = {};
        this.isConnected = false;

        const webSocketsService = window.APP.services.webSockets;

        this.init = webSocketsService.init.bind(webSocketsService);
        this.addSignalListener = webSocketsService.addSignalListener.bind(webSocketsService);
        this.removeSignalListener = webSocketsService.removeSignalListener.bind(webSocketsService);
        this.sendSignal = webSocketsService.sendSignal.bind(webSocketsService);
        this.addEventListener = webSocketsService.addEventListener.bind(webSocketsService);
        this.removeEventListener = webSocketsService.removeEventListener.bind(webSocketsService);
        this.restart = webSocketsService.restart.bind(webSocketsService);
    }

    /**
     * /!\ this method is never called from the webapp directly, but from packages
     * /!\ search for addAppscriptSignalListener in BE
     * @param args
     * @return {Promise<Function>}
     */
    async addAppscriptSignalListener(args) {
        const [ signalType, appscriptPath ] = args;
        const key = `${signalType}-${appscriptPath}`;
        const listener = signal =>
            this.databaseService.runAppScript(appscriptPath, signal);

        this.appScriptListeners[key] = listener;

        return await this.addSignalListener(signalType, listener);
    }

    async removeAppscriptSignalListener(args) {
        const [ signalType, appscriptPath ] = args;
        const key = `${signalType}-${appscriptPath}`;
        const listener = this.appScriptListeners[key];

        if (!listener) {
            return;
        }

        delete this.appScriptListeners[key];

        return await this.removeSignalListener(signalType, listener);
    }
}
